import { IPredmet } from "../components/models/IPredmet";

export const categories: IPredmet[] = [
    {
        lesson: "all",
        text: "Усі"
    },
    {
        lesson: "matematika",
        text: "Математика"
    }, 
    {
        lesson: "himiya",
        text: "Хімія"
    }, 
    {
        lesson: "istoriya",
        text: "Історія"
    },
]